/** @format */

import { TStore } from '@/redux/helpers/createStore';
import { TListType } from '../filters/declarations';

export interface TReducerGeneral {
  GTMListType: Array<TListType>;
  currentPage?: string;
  locale?: string;
  isLoading?: true;
  isLoadingConditions?: true;
  isViewMap?: boolean;
}

export const selectorGeneral = (store: TStore) => store.general;

export const selectorCurrentPage = (store: TStore) => store.general.currentPage;

export const selectorLocale = (store: TStore) => store.general.locale;

export const selectorViewMap = (store: TStore) => store.general.isViewMap;

export const selectorIsLoadingSearch = (store: TStore) => store.general?.isLoading;

export const selectorGtmListType = (store: TStore) =>
  store.general.GTMListType?.[store.general.GTMListType?.length - 1];
