/** @format */

/* eslint-disable import/no-unused-modules */
import moment from 'moment';
import { TApiSearchParams } from '../../types/apiSearch';

export const DEFAULT_DURATION = 1;

export const DEFAULT_PRICE_MIN = 100;
export const DEFAULT_PRICE_MAX = 1500;
export const DEFAULT_DISTANCE = 30000;
export const DATE_MINIMUM = moment();
export const DEFAULT_ORDER_BY = 'availability_asc';
export const DEFAULT_CHECK_IN_DATE = moment().toJSON();
// export const DEFAULT_CHECK_OUT_DATE = moment().add(3, 'days').toJSON();
export const NAME_DISTANCE_FROM_ADDRESS = 3000;

export const DEFAULT_FILTERS = {
  colivers: { min: 1 },
  singleRoom: true,
  colivingBuilding: true,
  entireStudio: true,
  monthlyPrice: {
    min: 100,
    max: 1500,
  },
  duration: 12,
  freeParking: false,
  checkInDate: DEFAULT_CHECK_IN_DATE,
  carParking: false,
  bikeParking: false,
  airConditioning: false,
  elevator: false,
  inSuiteLaundry: false,
  topFloor: false,
  lowerFloor: false,
  clothesDryer: false,
  parquet: false,
  internalSide: false,
  isAccessible: false,
  television: false,
  balcony: false,
  privateBathroom: false,
  concierge: false,
  gym: false,
  coworkingSpaces: false,
  bar: false,
};
export const DEFAULT_FILTERS_INITIAL_STATE = (cityName: string) => {
  return {
    area: {},
    checkInDate: DEFAULT_CHECK_IN_DATE,
    singleRoom: true,
    entireStudio: true,
    sharedApartment: true,
    colivingBuilding: true,
    orderBy: DEFAULT_ORDER_BY,
    monthlyPrice: {
      min: 100,
      max: 1500,
    },
    cityName: cityName,
    concierge: false,
    elevator: false,
    privateBathroom: false,
    balcony: false,
    bikeParking: false,
    topFloor: false,
    lowerFloor: false,
    parquet: false,
    clothesDryer: false,
    airConditioning: false,
    internalSide: false,
    gym: false,
    inSuiteLaundry: false,
  };
};
export const DEFAULT_FILTERS_INITIAL_STATE_FOR_POI = (cityName: string) => {
  return {
    checkInDate: DEFAULT_CHECK_IN_DATE,
    singleRoom: true,
    entireStudio: true,
    sharedApartment: true,
    colivingBuilding: true,
    orderBy: DEFAULT_ORDER_BY,
    monthlyPrice: {
      min: 100,
      max: 1500,
    },
    cityName: cityName,
    concierge: false,
    elevator: false,
    privateBathroom: false,
    balcony: false,
    bikeParking: false,
    topFloor: false,
    lowerFloor: false,
    parquet: false,
    clothesDryer: false,
    airConditioning: false,
    internalSide: false,
    gym: false,
    inSuiteLaundry: false,
  };
};

/**
Since backend has changed the sorting behaviour, we need to adapt the sorting object to the new format.

New Version:
@example
{
  sorting: {
    orderBy: 'availability',
    ascOrder: true
  }
}

Old version:
@example
{
  orderBy: 'availability_asc'
}
*/
export const getListingSorting = (sorting: string = DEFAULT_ORDER_BY) => {
  if (sorting === 'null') {
    return {
      sorting: {
        orderBy: 'availability',
        ascOrder: true,
      },
    };
  }
  return {
    sorting: {
      orderBy: sorting.split('_')[0],
      ascOrder: sorting.split('_')[1] === 'asc',
    },
  };
};

export const DEFAULT_FILTERS_API_SEARCH: TApiSearchParams['filters'] = {
  airConditioning: false,
  balcony: false,
  bikeParking: false,
  clothesDryer: false,
  concierge: false,
  internalSide: false,
  lowerFloor: false,
  topFloor: false,
  privateBathroom: false,
  elevator: false,
  colivingBuilding: false,
  singleRoom: false,
  entireStudio: false,
  oneBedroomApartment: false,
  monthlyPrice: {
    min: 100,
    max: 1500,
  },
};
