/** @format */

import { ListingFilters, ListingStore, SortingFields } from './types';

export const DEFAULT_FILTERS: ListingFilters = {
  amenities: {
    privateBathroom: false,
    wifi: false,
    balcony: false,
    airConditioning: false,
    dishwasher: false,
    dryer: false,
    washingMachine: false,
    internalSide: false,
    clothesDryer: false,
  },
  checkInDate: '',
  facilities: {
    bikeParking: false,
    concierge: false,
    elevator: false,
    topFloor: false,
    lowerFloor: false,
  },
  areas: undefined,
  monthlyPrice: {
    min: undefined,
    max: undefined,
  },
  propertyType: {
    colivingBuilding: false,
    entireStudio: false,
    singleRoom: false,
    oneBedroomApartment: false,
  },
  orderBy: SortingFields.NULL,
  suitability: {
    anyGender: false,
    femaleOnly: false,
    twoOccupantsAllowed: false,
  },
  positions: [],
};

export const INITIAL_STATE: ListingStore = {
  language: 'en',
  filters: DEFAULT_FILTERS,
  pageIndex: 1,
  results: {
    totalResults: 0,
  },
  isPoiUrlSet: false,
  searchByMap: false,
  loading: false,
  isDefaultFilters: true,
};

export const LISTING_MAX_ITEMS_PER_PAGE = 24;
