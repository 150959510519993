/** @format */
import { LISTING_MAX_ITEMS_PER_PAGE } from '../listing/constants';

export const RESULTS_SHOWN_ON_LIST = LISTING_MAX_ITEMS_PER_PAGE; // TODO to be removed
export const RESULTS_SHOWN_ON_MAP_MOBILE = 30;
export const RESULTS_SHOWN_ON_MAP_DESKTOP = 999;

export const DISCOVER_CITIES = [
  { it: 'Milano', en: 'Milan' },
  { it: 'Roma', en: 'Rome' },
  { it: 'Torino', en: 'Turin' },
  { it: 'Bologna', en: 'Bologna' },
  { it: 'Padova', en: 'Padua' },
  { it: 'Madrid', en: 'Madrid' },
  { it: 'Trento', en: 'Trento' },
];
