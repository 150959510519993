/** @format */

import { takeLatest } from 'redux-saga/effects';

import { sagaFilter } from '../filters/sagas';
import { sagaSearchList } from '../search/sagas';
import { sagaListing } from '@/ducks/listing/sagas';

export function* watcherGeneral(): Generator<any> {
  yield takeLatest('SET_FILTER', sagaFilter);
  yield takeLatest('LISTING_RESULT_REQUEST', sagaListing);
  // yield takeLatest('SEARCH_RESULT_LIST_REQUEST', sagaSearchList);
}
