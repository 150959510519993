/** @format */

import {
  DEFAULT_CHECK_IN_DATE,
  DEFAULT_FILTERS_API_SEARCH,
  DEFAULT_ORDER_BY,
  getListingSorting,
} from '../@ducks/filters/constants';
import { LISTING_MAX_ITEMS_PER_PAGE } from '../@ducks/listing/constants';
import { TApiBeds } from '../types/apiBeds';
import { TApiSearchParams } from '../types/apiSearch';
import { NAME_PORTAL_CODE } from '../utils/gatsby/constants';
import { Axios } from '../utils/gatsby/createAxiosInstance';

const getPromise = async (locale: 'it-it' | 'en-us', data: Omit<TApiSearchParams, 'language'>) => {
  const searchEndpont = `/Beds/${NAME_PORTAL_CODE}?language=${locale}`;
  return Axios.post<TApiBeds>(searchEndpont, data)
    .then(response => response.data)
    .catch(error => {
      throw new Error(
        `[getPromise] Request failed.\n${error}\nErrorBody: ${JSON.stringify(error.response.data, null, 2)}`,
        {
          cause: 'getPromise',
        },
      );
    });
};

export const apiSearch = async ({
  language,
  query,
  orderBy = DEFAULT_ORDER_BY,
  checkInDate = DEFAULT_CHECK_IN_DATE,
  positions = [],
  pageIndex = 1,
  districtIds,
  filters = DEFAULT_FILTERS_API_SEARCH,
  areas = [],
}: TApiSearchParams) => {
  const locale = language === 'it' ? 'it-it' : 'en-us';

  try {
    const response = await getPromise(locale, {
      query,
      ...getListingSorting(orderBy),
      checkInDate,
      positions,
      pageIndex,
      districtIds,
      filters,
      areas,
      maxItemsPerPage: LISTING_MAX_ITEMS_PER_PAGE,
    });

    return response;
  } catch (error) {
    if ((error as any).cause === 'getPromise') throw new Error(`[apiSearch] > ${error}`, { cause: 'apiSearch' });
    throw new Error(`[apiSearch] Request failed\n${error}`, {
      cause: 'apiSearch',
    });
  }
};
