/** @format */

import { TContentfulAction } from '@/redux/declarations';
import { INITIAL_STATE } from './constants';

export const reducerContentful = (prevState = INITIAL_STATE, action: TContentfulAction) => {
  switch (action.type) {
    case 'CONTENTFUL_SET_CONTENTFUL_DATA':
      return {
        ...prevState,
        data: { ...prevState.data, [action.payload.name]: action.payload.value },
      };

    default:
      return prevState;
  }
};
