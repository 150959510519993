/** @format */

import { apiSearch } from '@/api/apiSearch';
import { ListingStore } from '@/ducks/listing/types';
import { TListingAction } from '@/redux/declarations';
import { call, put } from 'redux-saga/effects';
import { TApiSearchParams } from '../../types/apiSearch';
import { getListingSorting } from '../filters/constants';

export function* sagaListing(action: TListingAction): Generator<any> {
  try {
    const params = marshalFiltersTo(action.payload);
    if (!action.payload.isDefaultFilters) {
      const payload = yield call(apiSearch, params);
      yield put({ type: 'LISTING_SET_RESULT_SUCCESS', payload });
    } else {
      yield put({ type: 'LISTING_SET_LOADING', payload: false });
    }
  } catch (error) {
    yield put({ type: 'LISTING_SET_RESULT_FAILURE' });
  }
}

type FilterInput = Omit<ListingStore, 'results' | 'resultCount'>;

export function marshalFiltersTo(filterInput: FilterInput): TApiSearchParams {
  const calcTenantConstraintType = (anyGender: boolean, femaleOnly: boolean) => {
    if (anyGender && femaleOnly) return undefined; // ?? TODO to be discussed
    if (anyGender) return 'NoRestriction';
    if (femaleOnly) return 'WomenOnly';

    return undefined;
  };

  const objectResponse: TApiSearchParams = {
    language: filterInput.language,
    query: filterInput.query ?? '',
    pageIndex: filterInput.pageIndex,
    orderBy: filterInput.filters.orderBy,
    checkInDate: filterInput.filters.checkInDate || new Date().toJSON(),
    positions: filterInput.filters.positions.map(single => ({
      latitude: single.latitude,
      longitude: single.longitude,
    })),
    filters: {
      airConditioning: filterInput.filters.amenities?.airConditioning ?? false,
      balcony: filterInput.filters.amenities?.balcony ?? false,
      bikeParking: filterInput.filters.facilities?.bikeParking ?? false,
      clothesDryer: filterInput.filters.amenities?.clothesDryer ?? false,
      concierge: filterInput.filters.facilities?.concierge ?? false,
      internalSide: filterInput.filters.amenities?.internalSide ?? false,
      lowerFloor: filterInput.filters.facilities?.lowerFloor ?? false,
      topFloor: filterInput.filters.facilities?.topFloor ?? false,
      privateBathroom: filterInput.filters.amenities?.privateBathroom ?? false,
      elevator: filterInput.filters.facilities?.elevator ?? false,
      colivingBuilding: filterInput.filters.propertyType?.colivingBuilding ?? false,
      singleRoom: filterInput.filters.propertyType?.singleRoom ?? false,
      entireStudio: filterInput.filters.propertyType?.entireStudio ?? false,
      oneBedroomApartment: filterInput.filters.propertyType?.oneBedroomApartment ?? false,
      tenantRestrictionType: calcTenantConstraintType(
        filterInput.filters.suitability?.anyGender ?? false,
        filterInput.filters.suitability?.femaleOnly ?? false,
      ),
      twoOccupantsAllowed: filterInput.filters.suitability?.twoOccupantsAllowed || undefined,
      monthlyPrice: {
        min: filterInput.filters.monthlyPrice?.min ?? 100,
        max: filterInput.filters.monthlyPrice?.max ?? 1500,
      },
    },
    areas: filterInput.filters.positions.length === 1 ? [] : filterInput.filters.areas, // TODO: avoided pagination bug when only one POI is selected and map is dragged
  };

  return objectResponse;
}
