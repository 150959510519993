/** @format */

import { utilityConvertAreaLabelToID } from '../../utils/helpers/convertAreaLabelToID';
import { TStore } from '../../redux/helpers/createStore';
import { DEFAULT_PRICE_MAX, DEFAULT_PRICE_MIN } from './constants';
import { TFilterArea } from './declarations';

export interface TReducerFilters {
  area: {
    [idArea: string]: boolean;
  };
  cityName?: string;
  singleRoom: boolean;
  entireStudio: boolean;
  colivingBuilding: boolean;
  orderBy: 'building' | 'shareapp' | 'pricing_asc' | 'availability_asc';
  sharedApartment: boolean;
  checkInDate: string;
  checkOutDate?: string;
  concierge?: boolean;
  elevator?: boolean;
  privateBathroom?: boolean;
  balcony?: boolean;
  bikeParking?: boolean;
  topFloor?: boolean;
  clothesDryer?: boolean;
  lowerFloor?: boolean;
  internalSide?: boolean;
  airConditioning?: boolean;
  address?: string;
  gym?: boolean;
  inSuiteLaundry?: boolean;
  parquet?: boolean;
  monthlyPrice: {
    min: number;
    max: number;
  };
}

export const selectorFilterCheckIn = (store: TStore) => store.filters.checkInDate;
export const selectorFilterCheckOut = (store: TStore) => store.filters.checkOutDate;

export const selectorFilterIsSingleRoom = (store: TStore) => store.filters.singleRoom || false;
export const selectorFilterIsEntireStudio = (store: TStore) => store.filters.entireStudio || false;
export const selectorFilterIsColivingBuilding = (store: TStore) => store.filters.colivingBuilding || false;

export const selectorFilterPriceMin = (store: TStore) => store.filters.monthlyPrice.min;
export const selectorFilterPriceMax = (store: TStore) => store.filters.monthlyPrice.max;

export const selectorFilterIsBudgetFiltered = (store: TStore) => {
  const priceMin = selectorFilterPriceMin(store);
  const priceMax = selectorFilterPriceMax(store);
  return priceMin !== DEFAULT_PRICE_MIN || priceMax !== DEFAULT_PRICE_MAX;
};

export const selectorFilterConcierge = (store: TStore) => store.filters.concierge || false;
export const selectorFilterElevator = (store: TStore) => store.filters.elevator || false;
export const selectorFilterPrivateBathroom = (store: TStore) => store.filters.privateBathroom || false;
export const selectorFilterBalcony = (store: TStore) => store.filters.balcony || false;
export const selectorFilterBikeParking = (store: TStore) => store.filters.bikeParking || false;
export const selectorFilterTopFloor = (store: TStore) => store.filters.topFloor || false;
export const selectorFilterClothesDryer = (store: TStore) => store.filters.clothesDryer || false;
export const selectorFilterLowerFloor = (store: TStore) => store.filters.lowerFloor || false;
export const selectorFilterInternalSide = (store: TStore) => store.filters.internalSide || false;
export const selectorFilterAirCondition = (store: TStore) => store.filters.airConditioning || false;
export const selectorFilterOrderBy = (store: TStore) => store.filters.orderBy;
export const selectorFilterAddress = (store: TStore) => store.filters.address || '';

export const selectorFilterMoreHasSelected = (store: TStore) => {
  const concierge = selectorFilterConcierge(store);
  const elevator = selectorFilterElevator(store);
  const privateBathroom = selectorFilterPrivateBathroom(store);
  const balcony = selectorFilterBalcony(store);
  const bikeParking = selectorFilterBikeParking(store);
  const topFloor = selectorFilterTopFloor(store);
  const clothesDryer = selectorFilterClothesDryer(store);
  const internalSide = selectorFilterInternalSide(store);
  const airConditioning = selectorFilterAirCondition(store);

  return (
    concierge ||
    elevator ||
    privateBathroom ||
    balcony ||
    bikeParking ||
    topFloor ||
    clothesDryer ||
    internalSide ||
    airConditioning
  );
};

export const selectorFilterTypeHasSelected = (store: TStore) => {
  const isSingleRoom = selectorFilterIsSingleRoom(store);
  const isColiving = selectorFilterIsColivingBuilding(store);
  const isEntireStudio = selectorFilterIsEntireStudio(store);

  return !isSingleRoom || !isColiving || !isEntireStudio;
};

export const selectorFilterIsAreaSelected = ({ store, area }: { store: TStore; area: TFilterArea }) => {
  const idArea = utilityConvertAreaLabelToID(area);
  return !!store.filters.area[idArea];
};
export const selectorFilterAreaSelected = (store: TStore) => store.filters.area;

export const selectorFilterQuery = (store: TStore) => store.filters.cityName;
