/** @format */

import { TStore } from '../../redux/helpers/createStore';
import {
  selectorFilterAirCondition,
  selectorFilterBalcony,
  selectorFilterBikeParking,
  selectorFilterCheckIn,
  selectorFilterClothesDryer,
  selectorFilterConcierge,
  selectorFilterElevator,
  selectorFilterInternalSide,
  selectorFilterIsColivingBuilding,
  selectorFilterIsEntireStudio,
  selectorFilterIsSingleRoom,
  selectorFilterLowerFloor,
  selectorFilterOrderBy,
  selectorFilterPriceMax,
  selectorFilterPriceMin,
  selectorFilterPrivateBathroom,
  selectorFilterQuery,
  selectorFilterTopFloor,
} from '../filters/selectors';
import { selectorLocale } from '../general/selectors';
import { RESULTS_SHOWN_ON_LIST } from './constants';

export type TReducerSearch = any;

export const selectorCityName = (store: TStore) => store.search.addressCenterMap || '';

export const selectorSearch = (store: TStore) => store.search;

export const selectorSearchAddressCenterMap = (store: TStore) => store.search.addressCenterMap;

export const selectorSearchAddress = (store: TStore) => store.search?.mapCenterAddress || '';

export const selectorSearchTotalResult = (store: TStore) => store.search?.totalResults || 0;
export const selectorSearchList = (store: TStore) => store.search?.items;
export const selectorAreaList = (store: TStore) => store.search?.pointsOfInterest;

export const selectorAreas = (store: TStore) => store.search?.areas;

export const selectorSearchMapPoints = (store: TStore) =>
  store.search?.items?.map(item => ({
    id: item.id,
    position: item.position,
    price: item.basePrice,
  }));

export const selectorShowMobileArea = (store: TStore) => store.search?.loadedAreas?.loaded && store.search.showFilters;
export const selectorSearchFlexibleItems = (store: TStore) => store.search?.flexibleItems;
export const selectorSearchTotalFlexibleItems = (store: TStore) => store.search?.flexibleItems?.totalItems;

export const selectorSearchFilters = (store: TStore) => {
  const singleRoom = selectorFilterIsSingleRoom(store);
  const entireStudio = selectorFilterIsEntireStudio(store);
  const min = selectorFilterPriceMin(store);
  const max = selectorFilterPriceMax(store);
  const airConditioning = selectorFilterAirCondition(store);
  const balcony = selectorFilterBalcony(store);
  const bikeParking = selectorFilterBikeParking(store);
  const clothesDryer = selectorFilterClothesDryer(store);
  const concierge = selectorFilterConcierge(store);
  const elevator = selectorFilterElevator(store);
  const internalSide = selectorFilterInternalSide(store);
  const privateBathroom = selectorFilterPrivateBathroom(store);
  const topFloor = selectorFilterTopFloor(store);
  const lowerFloor = selectorFilterLowerFloor(store);
  const colivingBuilding = selectorFilterIsColivingBuilding(store);

  return {
    // FILTERS MORE
    airConditioning,
    balcony,
    bikeParking,
    clothesDryer,
    concierge,
    internalSide,
    lowerFloor,
    topFloor,
    privateBathroom,
    elevator,
    // CATEGORY
    colivingBuilding,
    singleRoom,
    entireStudio,
    monthlyPrice: { min, max },
  };
};

export type TFilters = ReturnType<typeof selectorSearchFilters>;

export const selectorSearchPositions = (store: TStore) => {
  const areaList = Object.values(store.filters.area);
  if (!areaList) return [];
  const positions = areaList.map((area: any) => ({ latitude: area.latitude, longitude: area.longitude }));
  return positions;
};
export const selectorSearchPageIndex = (store: TStore) => store.search.pageIndex;
export const selectorSearchByAddress = (store: TStore) => store.filters.address;

export const selectorSearchRequest = (search: TStore['search']) => search?.searchRequest;

export const selectorDaysFlexibilty = (store: TStore) => {
  const search = selectorSearch(store);
  const searchResult = search?.searchResults;
  return searchResult?.flexibilityDaysNumber;
};

export const selectorSearchResultPerPage = (store: TStore) => {
  const search = selectorSearch(store);
  return search?.searchRequest?.maxItemsPerPage || RESULTS_SHOWN_ON_LIST;
};

export const selectorSearchMaxDistance = (store: TStore) => {
  const search = selectorSearch(store);
  const searchRequest = selectorSearchRequest(search);
  return searchRequest?.maxDistanceForSearch || 0;
};

export const selectorSearchBody = (store: TStore) => {
  const orderBy = selectorFilterOrderBy(store);
  const positions = selectorSearchPositions(store);
  const checkInDate = selectorFilterCheckIn(store);
  const language = selectorLocale(store);
  const areas = selectorAreas(store);
  const filters = selectorSearchFilters(store);
  const query =
    selectorSearchByAddress(store) !== undefined && selectorSearchByAddress(store) !== ''
      ? selectorSearchByAddress(store)
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .trim()
      : selectorFilterQuery(store);
  const pageIndex = 1;

  return {
    checkInDate,
    areas,
    filters,
    language,
    orderBy,
    positions,
    query,
    pageIndex,
  };
};
