/** @format */

import axios from 'axios';

import {
  API_KEY_AUTHENTICATION_DEV,
  API_KEY_AUTHENTICATION_PRODUCTION,
  API_KEY_AUTHENTICATION_STAGING,
  API_KEY_DOVEVIVO,
  Environment,
} from './constants';

const createConfig = () => {
  const baseConfig = {
    params: { key: API_KEY_DOVEVIVO },
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': API_KEY_AUTHENTICATION_STAGING },
  };

  const devBaseConfig = {
    params: { key: API_KEY_DOVEVIVO },
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': API_KEY_AUTHENTICATION_DEV },
  };

  const prodBaseConfig = {
    params: { key: API_KEY_DOVEVIVO },
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': API_KEY_AUTHENTICATION_PRODUCTION },
  };

  switch (process.env.GATSBY_ENV) {
    case 'staging':
      return { ...baseConfig, baseURL: Environment.booking };
    case 'production':
      return { ...prodBaseConfig, baseURL: Environment.booking };
    case 'develop':
    default:
      return { ...devBaseConfig, baseURL: Environment.booking };
  }
};

const config = createConfig();
const axiosInstance = axios.create(config);

export const Axios = axiosInstance;
