/** @format */

import { TAction } from '../../redux/declarations';

const INITIAL_STATE = {
  pageIndex: 1,
};

export const reducerSearch = (prevState = INITIAL_STATE as any, action: TAction) => {
  switch (action.type) {
    case 'SEARCH_AREAS_SET':
      return { ...prevState, areas: action.payload };
    case 'SEARCH_RESULT_LIST_SUCCESS':
      if (prevState.addressCenterMap === action.payload.addressCenterMap)
        return {
          ...prevState,
          ...action.payload,
          suggestedAreas: prevState.suggestedAreas,
          pageIndex: prevState.pageIndex,
        };
      return action.payload;
    case 'RESET_AREAS':
      return { ...prevState, areas: [] };
    case 'RESET_SEARCH_PARAMS':
      return INITIAL_STATE;
    case 'RESET_SEARCH_POI':
      return INITIAL_STATE;
    case 'SEARCH_PAGINATION_RESET':
      return { ...prevState, pageIndex: action.payload };
    case 'SEARCH_PAGINATION_SET':
      return { ...prevState, pageIndex: action.payload };
    default:
      return prevState;
  }
};
